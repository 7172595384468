import { StyleSheet } from '@react-pdf/renderer';
import { blue } from '@mui/material/colors';

// Create styles
export const styles = StyleSheet.create({
    head: {
        paddingHorizontal: 30,
        paddingVertical: 20,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    body: {
        paddingHorizontal: 30,
        paddingVertical: 20,
        gap: 10
    },
    total: {
        paddingHorizontal: 30,
        paddingTop: 20,
    },
    logo: {
        width: 100,
    },
    title: {
        fontWeight: 700,
        color: blue[700],
        fontSize: 20,
    },
    miniText: {
        fontSize: 10
    },
    smallText: {
        fontSize: 12
    },
    middleText: {
        fontSize: 16,
        fontWeight: 700
    },
    bigText: {
        fontSize: 20
    },
});