import { faCheck, faEnvelope, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Stack } from '@mui/joy'
import { CircularProgress } from '@mui/material'
import { EXIT_PAGE } from '../../../constant/constant'
import { blue, red } from '@mui/material/colors'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getInscrit } from '../../../function/getInscrit'
import { getTransactionStatue } from '../../../function/getTransactionStatue'
import { Helmet } from 'react-helmet'
import PaiementMethodeZone from './PaiementMethodeZone'
import LoaderZone from './LoaderZone'

const Paiement = () => {
    const { inscrits_id } = useParams("inscrits_id");
    const [transactionId, settransactionId] = useState(Math.floor(Math.random() * 100000000).toString())
    const [formData, setformData] = useState({});

    const [isLoaderZoneSow, setisLoaderZoneSow] = useState(true);
    const [textToShow, settextToShow] = useState("Recuperation de votre compte.");
    const [buttonText, setbuttonText] = useState("Retour au site.");
    const [loaderRedirectUrl, setloaderRedirectUrl] = useState(`${EXIT_PAGE}?tmp=${formData?.user_id}`);
    const [loaderZone, setloaderZone] = useState(
        <CircularProgress
            sx={{ alignSelf: "center" }}
        />
    );

    useEffect(
        () => {
            getTransactionStatue(transactionId).then(
                () => {
                    settextToShow("Votre paiement a bien été effectuer. Cliquez ici pour continuer vos activités");
                    setbuttonText("Retour au site.");
                    setloaderRedirectUrl(`${EXIT_PAGE}?tmp=${formData?.user_id}`);
                    setloaderZone(
                        <Avatar
                            sx={{
                                color: blue[100]
                            }}
                            variant='solid'
                            size='lg'
                        >
                            <FontAwesomeIcon icon={faCheck} />
                        </Avatar>
                    );
                }
            ).catch(
                () => {
                    settransactionId(Math.floor(Math.random() * 100000000).toString())
                }
            );

            getInscrit(inscrits_id).then(
                (data) => {
                    if (data.email_confirm === 1) {
                        setisLoaderZoneSow(false);

                        setformData(
                            {
                                inscrits_id: data.id,
                                nom: data.nom,
                                prenom: data.prenom,
                                email: data.adresse_electronique,
                                tel: data.phone,
                                adresse: data.adresse_physique,
                                ville: data.adresse_physique,
                                user_id: data.user_id
                            }
                        )

                    }
                    else {
                        settextToShow("Votre adresse email n'est pas confirmée. Nous vous invitons à le faire.")
                        setbuttonText("Cliquer pour confimer l'email.");
                        setloaderRedirectUrl(`/mailChecker/${data.id}`);

                        setloaderZone(
                            <Avatar
                                sx={{
                                    color: blue[100]
                                }}
                                variant='solid'
                                size='lg'
                            >
                                <FontAwesomeIcon icon={faEnvelope} />
                            </Avatar>
                        );
                    }
                }
            ).catch(
                (res) => {
                    settextToShow(`Une erreur est suvernue lors de la récuperation de votre compte. ${res?.message}.`)
                    setbuttonText(`Cliquez ici pour quitter.`);
                    setloaderRedirectUrl(EXIT_PAGE);

                    setloaderZone(
                        <Avatar
                            sx={{
                                color: red[500]
                            }}
                            variant='solid'
                            size='lg'
                        >
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                        </Avatar>
                    );
                }
            );
        }, []
    );

    return (
        <Stack
            alignItems={"center"}
            py={5}
        >
            <Helmet>
                <title>Paiement</title>
            </Helmet>

            {
                isLoaderZoneSow
                    ? (
                        <LoaderZone
                            isShow={isLoaderZoneSow}
                            textToShow={textToShow}
                            buttonText={buttonText}
                            loaderIcon={loaderZone}
                            redirectUrl={loaderRedirectUrl}
                        />
                    )
                    : (
                        <PaiementMethodeZone
                            formData={formData}
                            setbuttonText={setbuttonText}
                            setisLoaderZoneSow={setisLoaderZoneSow}
                            setloaderRedirectUrl={setloaderRedirectUrl}
                            setloaderZone={setloaderZone}
                            settextToShow={settextToShow}
                            transactionId={transactionId}
                        />
                    )
            }
        </Stack >
    )
}

export default Paiement