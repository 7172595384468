import { PDFViewer } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { CINETPAY_METHODE_PAYMENT_LIST, CONTACT, IMAGE } from '../../../constant/constant';
import { blue } from '@mui/material/colors';
import { styles } from './styles';
import Separator from '../../../Component/Recu/Separator';
import { getAbonnement } from '../../../function/getAbonnement';

const AbonnementRecu = () => {
    const { abonnements_id } = useParams();

    const [id_transaction, setid_transaction] = useState(null);
    const [datePaiement, setdatePaiement] = useState(null)
    const [montant, setmontant] = useState(null)
    const [nom, setnom] = useState(null)
    const [prenom, setprenom] = useState(null)
    const [phone, setphone] = useState(null)
    const [adresse, setadresse] = useState(null)
    const [methode, setmethode] = useState(null)
    const [expireDate, setexpireDate] = useState(null)
    const [dateActivation, setdateActivation] = useState(null)

    const findPaiementMethode = () => {
        return CINETPAY_METHODE_PAYMENT_LIST.find(
            value => value.code == methode
        );
    }

    useEffect(
        () => {
            getAbonnement(abonnements_id).then(
                (data) => {
                    setmontant(data.montant);
                    setdatePaiement(data.request_date);
                    setnom(data.nom);
                    setprenom(data.prenom);
                    setphone(data.phone);
                    setadresse(data.adresse_physique);
                    setmethode(data.moyen);
                    setexpireDate(data.expiration);
                    setdateActivation(data.dateActivation);
                }
            );

            setid_transaction(abonnements_id);
        },
        []
    )

    return (
        id_transaction
            ? (
                <PDFViewer
                    style={{
                        width: "calc(100vw - 5px)",
                        height: "calc(100vh - 8px)",
                    }}
                >
                    <Document
                        title={`Recu de paiement ${id_transaction}`}
                        author='Site Web ICP'
                        keywords='Recu'
                        creator='ICP'
                        language='fr'
                    >
                        <Page
                            size="A4"
                            style={styles.page}
                        >
                            <Separator />

                            <View style={styles.head}>
                                <View>
                                    <Image
                                        src={IMAGE.logo}
                                        style={styles.logo}
                                    />
                                    <Text
                                        style={styles.miniText}
                                    >{CONTACT.tel}</Text>
                                    <Text
                                        style={styles.miniText}
                                    >{CONTACT.email}</Text>
                                </View>
                                <View
                                    style={{
                                        gap: 10,
                                        alignItems: "flex-end"
                                    }}
                                >
                                    <Text
                                        style={styles.title}
                                    >REÇU D'ABONNEMENT</Text>
                                    <Text>N: {id_transaction}</Text>
                                    <Text>Date de paiement: {dateActivation}</Text>
                                </View>
                            </View>

                            <Separator height={10} />

                            <View
                                style={[
                                    styles.body,
                                    styles.middleText
                                ]}
                            >
                                <Text
                                    style={styles.title}
                                >Informations sur le client</Text>

                                <Separator height={1} />

                                <Text>
                                    Nom du client:
                                    <Text
                                        style={styles.middleText}
                                    >
                                        {` `} {nom}
                                    </Text>
                                </Text>
                                <Separator height={1} />

                                <Text>
                                    Prenom du client:
                                    <Text
                                        style={styles.middleText}
                                    >
                                        {` `} {prenom}
                                    </Text>
                                </Text>
                                <Separator height={1} />

                                <Text>
                                    Adresse physique du client:
                                    <Text
                                        style={styles.middleText}
                                    >
                                        {` `} {adresse}
                                    </Text>
                                </Text>
                                <Separator height={1} />

                                <Text>
                                    Contact du client:
                                    <Text
                                        style={styles.middleText}
                                    >
                                        {` `} {phone}
                                    </Text>
                                </Text>
                            </View>

                            <View
                                style={[
                                    styles.body,
                                    styles.middleText
                                ]}
                            >
                                <Text
                                    style={styles.title}
                                >Informations sur l'abonnement</Text>

                                <Separator height={1} />

                                <Text>
                                    Date de paiement:
                                    <Text
                                        style={styles.middleText}
                                    >
                                        {` `} {datePaiement}
                                    </Text>
                                </Text>
                                <Separator height={1} />

                                <Text>
                                    Methode de paiement:
                                    <Text
                                        style={styles.middleText}
                                    >
                                        {` `}
                                        {findPaiementMethode()?.value}

                                    </Text>
                                </Text>
                                <Separator height={1} />

                                <Text>
                                    Date d'expiration:
                                    <Text
                                        style={styles.middleText}
                                    >
                                        {` `}
                                        {expireDate}

                                    </Text>
                                </Text>

                            </View>

                            <View
                                style={{
                                    backgroundColor: blue[700],
                                    paddingHorizontal: 30,
                                    paddingVertical: 10,
                                    flexDirection: "row",
                                    color: "white",
                                }}
                            >
                                <View
                                    style={{
                                        width: "80%",
                                    }}
                                >
                                    <Text>Description</Text>
                                </View>
                                <View
                                    style={{
                                        textAlign: "right",
                                        width: "20%",
                                    }}
                                >
                                    <Text>Montant</Text>
                                </View>
                            </View>

                            <View
                                style={[
                                    styles.smallText,
                                    {
                                        backgroundColor: "#e4e4e4",
                                        paddingHorizontal: 30,
                                        paddingVertical: 10,
                                        flexDirection: "row",
                                        marginTop: 5,
                                    }
                                ]}
                            >
                                <View
                                    style={{
                                        width: "80%",
                                    }}
                                >
                                    <Text>Paiement abonnement 12 mois</Text>
                                </View>
                                <View
                                    style={{
                                        textAlign: "right",
                                        width: "20%",
                                    }}
                                >
                                    <Text>{montant} {findPaiementMethode()?.devise}</Text>
                                </View>

                            </View>

                            <View
                                style={styles.total}
                            >
                                <View
                                    style={{
                                        flexDirection: "row",
                                        gap: 40,
                                        alignSelf: 'flex-end',
                                    }}
                                >
                                    <Text
                                        style={styles.title}
                                    >
                                        Total TTC
                                    </Text>

                                    <Text>
                                        {montant} {findPaiementMethode()?.devise}
                                    </Text>
                                </View>
                                <Separator height={1} />
                            </View>
                        </Page>
                    </Document>
                </PDFViewer >
            )
            : undefined
    )
};

export default AbonnementRecu;