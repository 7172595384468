import { faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AspectRatio, Avatar, Card, Stack, Typography } from '@mui/joy'
import { Button, CircularProgress } from '@mui/material'
import { EXIT_PAGE, IMAGE } from '../../../constant/constant'
import { red } from '@mui/material/colors'
import { getPaimentToken } from '../../../function/getPaiementToken'

const PaiementMethodeZone = ({
    settextToShow,
    setloaderZone,
    setbuttonText,
    setloaderRedirectUrl,
    formData,
    setisLoaderZoneSow,
    transactionId
}) => {
    const handleSubmitForm = (isMethodPaiementMobileMoney = true) => {
        setisLoaderZoneSow(true);
        settextToShow("Configuration du guichet de paiement");
        setloaderZone(
            <CircularProgress />
        );
        setbuttonText("Annuler");
        setloaderRedirectUrl(`${EXIT_PAGE}?tmp=${formData?.user_id}`);

        getPaimentToken(
            formData.inscrits_id,
            formData.nom,
            formData.prenom,
            formData.email,
            formData.tel,
            isMethodPaiementMobileMoney ? undefined : `Abidjan`,
            isMethodPaiementMobileMoney ? undefined : `Abidjan`,
            isMethodPaiementMobileMoney ? undefined : `CI`,
            isMethodPaiementMobileMoney ? undefined : `CI`,
            isMethodPaiementMobileMoney ? undefined : `225`,
            transactionId,
            formData?.user_id,
            isMethodPaiementMobileMoney ? "MOBILE_MONEY" : "CREDIT_CARD"
        ).catch(
            () => {
                setloaderZone(
                    <Avatar
                        sx={{
                            color: red[500]
                        }}
                        variant='solid'
                        size='lg'
                    >
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                    </Avatar>
                );
                settextToShow("Une erreur est survenue lors la soumission veuillez verifier vos informations et réessayer. Si le probleme persiste, Veuillez contacter l'entreprise.");
            }
        ).then(
            response => {
                setloaderZone(
                    <CircularProgress />
                );
                settextToShow("Chargement en cours");

                window.location.href = response.data.payment_url;
            }
        )
    }

    return (
        <Stack
            height={500}
            justifyContent={"center"}
            gap={2}
        >
            <Stack
                direction={"row"}
                gap={2}
                sx={{
                    "& > *": {
                        cursor: "pointer",
                        width: 150,
                        p: 1
                    },
                    "& > *:hover": {
                        transform: `scale(1.01)`
                    },
                }}
            >
                <Card
                    variant="outlined"
                    onClick={() => {
                        handleSubmitForm && handleSubmitForm(true)
                    }}
                >
                    <AspectRatio
                        ratio={"4/3"}
                    >
                        <Stack>
                            <img src={IMAGE.mobileMoney} />
                        </Stack>
                    </AspectRatio>
                    <Stack>
                        <Typography level="title-md">Mobile Money</Typography>
                        <Typography
                            level="body-sm"
                            sx={{
                                textWrap: "nowrap"
                            }}
                        >Orange Money / Moov ...</Typography>
                    </Stack>
                </Card>

                <Card
                    variant="outlined"
                    onClick={() => {
                        handleSubmitForm && handleSubmitForm(false)
                    }}
                >
                    <AspectRatio
                        ratio={"4/3"}
                    >
                        <img src={IMAGE.visaMasterCard} />
                    </AspectRatio>
                    <Stack>
                        <Typography level="title-md">Carte Bancaire</Typography>
                        <Typography level="body-sm">Visa / Master Card.</Typography>
                    </Stack>
                </Card>
            </Stack>
            <Button
                color='warning'
                variant='outlined'
                startIcon={
                    <FontAwesomeIcon icon={faTimesCircle} />
                }
                type='reset'
                onClick={() => {
                    window.location.href = `${EXIT_PAGE}?tmp=${formData?.user_id}`;
                }}
            >
                Annuler
            </Button>
        </Stack>
    )
}

export default PaiementMethodeZone