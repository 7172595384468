import axios from "axios";
import { API_T } from "../constant/objectType";

export const valide_mail = (inscrits_id: number) : Promise <API_T> => {
    return new Promise((resolve, reject) => {
        axios.postForm(
            `${process.env.REACT_APP_API_URL}/api/inscrit/valide_email.php`,
            {
                inscrits_id: inscrits_id
            }
        ).then(
            res => {
                const data: API_T = res.data;

                console.log('====================================');
                console.table(data);
                console.log('====================================');

                resolve(data);
            }
        ).catch(
            err => {
                console.error('====================================');
                console.error(err);
                console.error('====================================');

                reject(err);
            }
        )
    })
}