import { PDFDownloadLink } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getInscritLastpaiement } from '../../../function/getInscritLastpaiement';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { CONTACT, IMAGE } from '../../../constant/constant';
import { blue, orange, red } from '@mui/material/colors';
import { styles } from './styles';
import Separator from '../../../Component/Recu/Separator';
import { AspectRatio, Card, CardContent, Stack, Typography } from '@mui/joy';
import { Avatar, CircularProgress } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faTimes } from '@fortawesome/free-solid-svg-icons';

const RecuLoaderZone = ({
    icon = <FontAwesomeIcon icon={faTimes} />,
    text = 'Quitter',
    onClick,
    color
}) => {
    return (
        <AspectRatio
            ratio={"1/1"}
            sx={{
                width: 150,
                cursor: "pointer"
            }}
            onClick={() => onClick && onClick()}
        >
            <Card>
                <CardContent
                    sx={{
                        gap: 2
                    }}
                >
                    <Avatar
                        sx={{
                            width: 80,
                            height: 80,
                            bgcolor: color
                        }}
                    >
                        {icon}
                    </Avatar>
                    <Typography textColor={color} fontWeight={700}>{text}</Typography>
                </CardContent>
            </Card>
        </AspectRatio>
    )
}

const PaiementRecu = () => {
    const { inscrits_id } = useParams();

    const [montant, setmontant] = useState(null);
    const [devise, setdevise] = useState(null);
    const [idOperateur, setidOperateur] = useState(null);
    const [date, setdate] = useState(null);
    const [id_transaction, setid_transaction] = useState(null);
    const [description, setdescription] = useState(null);

    const [isRecuLoaded, setisRecuLoaded] = useState(null);

    useEffect(
        () => {
            getInscritLastpaiement(inscrits_id).then(
                (data) => {
                    setmontant(data.amount);
                    setdevise(data.currency);
                    setidOperateur(data.operator_id);
                    setdescription(data.description);
                    setdate(data.payment_date);
                    setid_transaction(data.id_transaction);
                }
            )
        },
        []
    );

    return (
        <Stack
            height={"100vh"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
        >
            {
                isRecuLoaded && (
                    <Typography level='h2'>Votre réçu est pret.</Typography>
                )
            }

            <Stack
                direction={"row"}
                gap={2}
            >
                <RecuLoaderZone
                    onClick={() => window.close()}
                />

                {
                    id_transaction
                        ? (
                            <PDFDownloadLink
                                document={
                                    <Document
                                        title={`Recu de paiement ${id_transaction}`}
                                        author='Site Web ICP'
                                        keywords='Recu'
                                        creator='ICP'
                                        language='fr'
                                    >
                                        <Page
                                            size="A4"
                                            style={styles.page}
                                        >
                                            <Separator />

                                            <View style={styles.head}>
                                                <View>
                                                    <Image
                                                        src={IMAGE.logo}
                                                        style={styles.logo}
                                                    />
                                                    <Text
                                                        style={styles.miniText}
                                                    >{CONTACT.tel}</Text>
                                                    <Text
                                                        style={styles.miniText}
                                                    >{CONTACT.email}</Text>
                                                </View>
                                                <View
                                                    style={{
                                                        gap: 10,
                                                        alignItems: "flex-end"
                                                    }}
                                                >
                                                    <Text
                                                        style={styles.title}
                                                    >REÇU DE PAIEMENT</Text>
                                                    <Text>N: {id_transaction}</Text>
                                                    <Text>Date de paiement: {date}</Text>
                                                </View>
                                            </View>

                                            <Separator height={10} />

                                            <View
                                                style={[
                                                    styles.body,
                                                    styles.middleText
                                                ]}
                                            >
                                                <Text
                                                    style={styles.title}
                                                >Informations sur le paiement</Text>

                                                <Separator height={1} />

                                                <Text>
                                                    ID Transaction:
                                                    <Text
                                                        style={styles.middleText}
                                                    >
                                                        {` `} {id_transaction}
                                                    </Text>
                                                </Text>
                                                <Separator height={1} />

                                                <Text>
                                                    ID Operateur:
                                                    <Text
                                                        style={styles.middleText}
                                                    >
                                                        {` `} {idOperateur}
                                                    </Text>
                                                </Text>
                                            </View>

                                            <View
                                                style={{
                                                    backgroundColor: blue[700],
                                                    paddingHorizontal: 30,
                                                    paddingVertical: 10,
                                                    flexDirection: "row",
                                                    color: "white",
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        width: "80%",
                                                    }}
                                                >
                                                    <Text>Description</Text>
                                                </View>
                                                <View
                                                    style={{
                                                        textAlign: "right",
                                                        width: "20%",
                                                    }}
                                                >
                                                    <Text>Montant</Text>
                                                </View>
                                            </View>

                                            <View
                                                style={[
                                                    styles.smallText,
                                                    {
                                                        backgroundColor: "#e4e4e4",
                                                        paddingHorizontal: 30,
                                                        paddingVertical: 10,
                                                        flexDirection: "row",
                                                        marginTop: 5,
                                                    }
                                                ]}
                                            >
                                                <View
                                                    style={{
                                                        width: "80%",
                                                    }}
                                                >
                                                    <Text>{description}</Text>
                                                </View>
                                                <View
                                                    style={{
                                                        textAlign: "right",
                                                        width: "20%",
                                                    }}
                                                >
                                                    <Text>{montant} {devise}</Text>
                                                </View>

                                            </View>

                                            <View
                                                style={styles.total}
                                            >
                                                <View
                                                    style={{
                                                        flexDirection: "row",
                                                        gap: 40,
                                                        alignSelf: 'flex-end',
                                                    }}
                                                >
                                                    <Text
                                                        style={styles.title}
                                                    >
                                                        Total TTC
                                                    </Text>

                                                    <Text>
                                                        {montant} {devise}
                                                    </Text>
                                                </View>
                                                <Separator height={1} />
                                            </View>
                                        </Page>
                                    </Document>
                                }
                            >
                                {({ loading }) => {
                                    setisRecuLoaded(true);

                                    return (
                                        loading
                                            ? (
                                                <RecuLoaderZone
                                                    text='Chargement'
                                                    icon={<CircularProgress />}
                                                />
                                            )
                                            : (
                                                <RecuLoaderZone
                                                    icon={<FontAwesomeIcon icon={faFileDownload} />}
                                                    text='Telecharger'
                                                    color={blue[700]}
                                                />
                                            )
                                    );
                                }}
                            </PDFDownloadLink >
                        )
                        : undefined
                }
            </Stack >
        </Stack>

    )
};

export default PaiementRecu;