import { View } from '@react-pdf/renderer';
import { blue } from '@mui/material/colors';


const Separator = ({ height, bgColor }) => {
    return (
        <View
            style={{
                height: height || 40,
                backgroundColor: bgColor || blue[700]
            }}
        ></View>
    )
}

export default Separator