import axios from "axios";
import { getAbonnement_T } from "../constant/objectType";

/**
 * Retrieves an abonnement by its ID.
 *
 * @param {number} abonnements_id - The ID of the abonnement to retrieve.
 * @returns {Promise<getAbonnement_T>} A promise that resolves with the retrieved abonnement data.
 *
 * @example
 * const abonnementId = 123;
 * getAbonnement(abonnementId).then((data) => {
 *   console.log(data); // Output: { id: 123, name: "Abonnement 1", ... }
 * }).catch((err) => {
 *   console.error(err);
 * });
 */
export const getAbonnement = (abonnements_id: number): Promise<getAbonnement_T> => {
    return new Promise((resolve, reject) => {
        axios.postForm(
            `${process.env.REACT_APP_API_URL}/api/abonnement/getAbonnement.php`,
            {
                abonnements_id: abonnements_id
            }
        ).then(
            res => {
                const data: getAbonnement_T = res.data.data;

                console.log('====================================');
                console.table(data);
                console.log('====================================');

                resolve(data);
            }
        ).catch(
            err => {
                console.error('====================================');
                console.error(err);
                console.error('====================================');

                reject(err);
            }
        )
    })
}