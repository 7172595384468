import { Stack } from '@mui/joy'
import { Button, CircularProgress } from '@mui/material'
import { EXIT_PAGE } from '../../../constant/constant'
import { grey } from '@mui/material/colors'

const LoaderZone = (
    {
        isShow = true,
        textToShow = "Votre paiement a bien été effectuer. Cliquez ici pour continuer vos activités",
        buttonText = "Retour au site.",
        loaderIcon = <CircularProgress />,
        redirectUrl = EXIT_PAGE
    }
) => {
    return (
        isShow
            ? (
                <Stack
                    width={350}
                    gap={2}
                    bgcolor={grey[100]}
                    p={1}
                    borderRadius={10}
                >
                    {/* <Avatar
                        sx={{
                            alignSelf: "center",
                            color: red[500]
                        }}
                        variant='solid'
                        size='lg'
                    >
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                    </Avatar> */}
                    <Stack
                        alignSelf={"center"}
                    >
                        {
                            loaderIcon
                        }
                    </Stack>

                    {textToShow}

                    <Button
                        fullWidth
                        href={redirectUrl}
                        variant='outlined'
                    >
                        {buttonText}
                    </Button>
                </Stack>
            )
            : undefined
    )

}

export default LoaderZone