import { Route, Routes } from "react-router-dom"
import MailChecker from "../../Pages/mailChecker/MailChecker"
import Paiement from "../../Pages/Paiement/Guichet"
import PaiementRecu from "../../Pages/Paiement/Recu"
import AbonnementRecu from "../../Pages/abonnement/Recu"

const Router = () => {
  return (
    <Routes >
      <Route path="/*" element={<MailChecker />} />

      <Route path="/:inscrits_id" element={<Paiement />} />

      <Route path="/mailChecker/*" element={<MailChecker />} />
      <Route path="/mailChecker/:inscrits_id/:isConfirmation" element={<MailChecker />} />
      <Route path="/mailChecker/:inscrits_id" element={<MailChecker />} />

      <Route path="/paiement/*" element={<Paiement />} />
      <Route path="/paiement/:inscrits_id" element={<Paiement />} />
      
      <Route path="/paiementSuccess/*" element={<PaiementRecu />} />
      <Route path="/paiementSuccess/:inscrits_id" element={<PaiementRecu />} />
      
      <Route path="/abonnementRecu/*" element={<AbonnementRecu />} />
      <Route path="/abonnementRecu/:abonnements_id" element={<AbonnementRecu />} />
    </Routes>
  )
}

export default Router