import axios from "axios";
import { GET_INSCRITS_LAST_TRANSACTION } from "../constant/objectType";

export const getInscritLastpaiement = (inscrits_id: number) : Promise <GET_INSCRITS_LAST_TRANSACTION> => {
    return new Promise((resolve, reject) => {
        axios.postForm(
            `${process.env.REACT_APP_API_URL}/api/inscrit/getLastPaiement.php`,
            {
                inscrits_id: inscrits_id
            }
        ).then(
            res => {
                const data: GET_INSCRITS_LAST_TRANSACTION = res.data.data;

                console.log('====================================');
                console.table(data);
                console.log('====================================');

                resolve(data);
            }
        ).catch(
            err => {
                console.error('====================================');
                console.error(err);
                console.error('====================================');

                reject(err);
            }
        )
    })
}