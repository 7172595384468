import axios from "axios";
import { GET_TRANSACTION_STATUE_T } from "../constant/objectType";

/**
 * Retrieves the transaction status from CinetPay API
 *
 * @param {string | number} transaction_id - Transaction ID to retrieve status for
 * @returns {Promise<REQUEST_T>} - Promise resolving to the transaction status response
 *
 * @example
 * getTransactionStatus('1234567890').then((response) => {
 *   console.log(response.data.status); // "success" or "failed"
 * }).catch((error) => {
 *   console.error(error);
 * });
 */
export const getTransactionStatue = (
    transaction_id: string | number
): Promise<GET_TRANSACTION_STATUE_T> => {
    return new Promise((resolve, reject) => {
        const data = {
            apikey: process.env.REACT_APP_CINETPAY_APIKEY,
            site_id: process.env.REACT_APP_CINETPAY_SITEID,
            transaction_id: transaction_id, //
        };

        axios.postForm(
            `${process.env.REACT_APP_CINETPAY_URL}/check`,
            data
        ).then(
            (res) => {
                console.log('====================================');
                console.table(res.data);
                console.log('====================================');

                const result: GET_TRANSACTION_STATUE_T = res.data;

                resolve(result);
            }
        ).catch(
            (err) => {
                console.error('====================================');
                console.table(err);
                console.error('====================================');

                reject(err);
            }
        )
    })
}