import axios from "axios"

/**
 * Sends an email using the ICP API.
 * 
 * @param {string} mail - The recipient's email address.
 * @param {string} message - The email message.
 * @param {string} objet - The email subject.
 * @param {string[] | undefined | null} multiAdress : - An array of multiple recipient email address.
 * 
 * @returns {Promise<{ message: string; code: number; data: any; }>} A promise that resolves with the API response.
 * 
 * @example
 * sendMail('recipient@example.com', 'Hello, world!', 'Test Email')
 *   .then(response => console.log(response))
 *   .catch(error => console.error(error));
 */
export const sendMail = (mail: string, message: any, objet: string, multiAdress: string[] | undefined | null): Promise<{ message: string; code: number; data: any; }> => {
    return new Promise((resolve, reject) => {
        axios.postForm(
            `${process.env.REACT_APP_API_URL}/api/mail_sender/index.php`,
            {
                email: mail,
                message: message,
                objet: objet,
                multiAdress: multiAdress
            }
        ).then(
            res => {
                const data: {
                    message: string,
                    code: number,
                    data: any,
                } = res.data;

                console.log('====================================');
                console.table(data);
                console.log('====================================');

                resolve(data);
            }
        ).catch(
            err => {
                console.error('====================================');
                console.error(err);
                console.error('====================================');

                reject(err);
            }
        )
    })
}