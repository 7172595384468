import { faArrowCircleLeft, faCheckCircle, faEnvelopeCircleCheck, faExclamationTriangle, faMoneyBillTransfer, faRotate, faTimes, faTimesCircle, faUserAstronaut } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, CircularProgress, Input, Stack, Typography } from '@mui/joy'
import { Button, ButtonGroup } from '@mui/material'
import { grey, red } from '@mui/material/colors'
import { useEffect, useRef, useState } from 'react'
import { sendMail } from '../../function/sendMail'
import { getInscrit } from '../../function/getInscrit'
import { valide_mail } from '../../function/valide_mail'
import { useParams } from 'react-router-dom'
import { PROFIL_PAGE_PATH } from '../../constant/constant'
import { Helmet } from 'react-helmet'

const MailChecker = () => {

    const inputRef = useRef < HTMLInputElement | null > (null);

    const { inscrits_id, isConfirmation } = useParams();

    /*
    *null for padding
    *true for sended
    *false for not sended
    */
    const [mailSendStatut, setmailSendStatut] = useState(null);

    const [currentUser, setcurrentUser] = useState(null);
    const [currentCode, setcurrentCode] = useState((Math.floor(Math.random() * (999999 - 100000)) + 100000));
    const [showInputZone, setshowInputZone] = useState(false);
    const [value, setvalue] = useState(null);
    const [textToShow, settextToShow] = useState("Veuillez patienter. Nous verifions si votre email est valide.");
    const [loaderZone, setloaderZone] = useState(
        <CircularProgress
            sx={{
                alignSelf: "center"
            }}
        />
    );


    const checkCode = () => {
        return value == currentCode;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (checkCode()) {
            valide_mail(currentUser?.id).then(
                () => {
                    settextToShow(
                        <>
                            Votre Mail a été validé avec success.

                            {
                                isConfirmation
                                    ? (
                                        <Button
                                            fullWidth
                                            href={`${PROFIL_PAGE_PATH}?tmp=${currentUser?.user_id}`}
                                        >
                                            Aller au profil
                                            <FontAwesomeIcon icon={faUserAstronaut} />
                                        </Button>
                                    )
                                    : (
                                        <Button
                                            fullWidth
                                            href={`/paiement/${currentUser?.id}`}
                                        >
                                            Procéder au paiement
                                            <FontAwesomeIcon icon={faMoneyBillTransfer} />
                                        </Button>
                                    )
                            }


                        </>
                    );

                    setshowInputZone(false);
                }
            ).catch(
                () => {
                    settextToShow(
                        <>
                            Une erreur est survenue lors de la validation de votre email.
                            <Button
                                fullWidth
                                href={`${PROFIL_PAGE_PATH}?tmp=${currentUser?.user_id}`}
                            >
                                Quitter
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                            <Button
                                fullWidth
                                href={`/mailChecker/${inscrits_id}`}
                            >
                                Réessayer
                                <FontAwesomeIcon icon={faRotate} />
                            </Button>
                        </>
                    );
                    alert("Erreur lors de la validation de votre email");
                }
            )
        }
        else {
            settextToShow(`Code incorrecte. Verifiew votre boite mail ${currentUser?.adresse_electronique}`);
        }
    }

    useEffect(
        () => {
            !inscrits_id && window.history.back();

            getInscrit(inscrits_id).then(
                (data) => {
                    setcurrentUser(data);

                    if (data.email_confirm != 1) {
                        settextToShow(
                            <>Envoie d'un code à 6 chiffres sur l'email <b>{data.adresse_electronique}</b></>
                        );

                        sendMail(
                            data.adresse_electronique,
                            `Votre code de verification est ${currentCode}`,
                            "Votre code de verification"
                        ).then(
                            () => {
                                setmailSendStatut(true);
                                setshowInputZone(true);
                            }
                        ).catch(
                            () => setmailSendStatut(false)
                        );
                    }
                    else {
                        settextToShow(
                            <>
                                Votre adresse email a déja confirmé.
                                {
                                    isConfirmation
                                        ? (
                                            <Button
                                                fullWidth
                                                href={`${PROFIL_PAGE_PATH}?tmp=${currentUser?.user_id}`}
                                            >
                                                Aller au profil
                                                <FontAwesomeIcon icon={faUserAstronaut} />
                                            </Button>
                                        )
                                        : (
                                            <Button
                                                fullWidth
                                                href={`/paiement/${data.id}`}
                                            >
                                                Procéder au paiment
                                                <FontAwesomeIcon icon={faMoneyBillTransfer} />
                                            </Button>
                                        )
                                }
                            </>
                        );

                        setloaderZone(
                            <Avatar
                                sx={{
                                    alignSelf: "center",
                                }}
                                variant='solid'
                                size='lg'
                            >
                                <FontAwesomeIcon icon={faUserAstronaut} />
                            </Avatar>
                        )
                    }
                }
            ).catch(
                res => {
                    setloaderZone(
                        <Avatar
                            sx={{
                                alignSelf: "center",
                                color: red[500]
                            }}
                            variant='solid'
                            size='lg'
                        >
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                        </Avatar>
                    );

                    settextToShow(
                        <>
                            Une erreur est suvernue lors de la récuperation de votre compte. {res?.message}.
                            <Button
                                fullWidth
                                href={`${PROFIL_PAGE_PATH}?tmp=${currentUser?.user_id}`}
                            >
                                Cliquez ici pour quitter.
                                <FontAwesomeIcon icon={faArrowCircleLeft} />
                            </Button>
                        </>
                    );
                }
            )
        },
        []
    );

    useEffect(
        () => {
            switch (mailSendStatut) {
                case true:
                    setloaderZone(
                        <Avatar
                            sx={{
                                alignSelf: "center"
                            }}
                            variant='solid'
                            size='lg'
                        >
                            <FontAwesomeIcon icon={faEnvelopeCircleCheck} />
                        </Avatar>
                    );

                    settextToShow(
                        <>
                            Veuillez saisir le <b>code</b> qui
                            vous a été envoyé au <b>{currentUser?.adresse_electronique}</b>.
                        </>
                    );

                    break;

                case null:
                    setloaderZone(
                        <CircularProgress
                            sx={{
                                alignSelf: "center"
                            }}
                        />
                    );

                    settextToShow(`Veuillez patienter. Nous verifions si votre email est valide.`);

                    break;

                case false:
                    setloaderZone(
                        <Avatar
                            sx={{
                                alignSelf: "center",
                                color: red[500]
                            }}
                            variant='solid'
                            size='lg'
                        >
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                        </Avatar>
                    );

                    settextToShow(
                        <>
                            Une erreur est survenue. Veuillez réessayer plutard. Si le problème persiste, veuillez contacter notre équipe.
                            <Button
                                fullWidth
                                href={`${PROFIL_PAGE_PATH}?tmp=${currentUser?.user_id}`}
                            >
                                Cliquez ici pour quitter.
                                <FontAwesomeIcon icon={faArrowCircleLeft} />
                            </Button>
                        </>
                    );

                    break;
            }
        },
        [mailSendStatut]
    )


    return (
        <Stack
            width={"100vw"}
            height={"100vh"}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Helmet>
                <title>Validation d'email</title>
            </Helmet>
            <form
                onSubmit={(e) => {
                    handleSubmit(e);
                }}
            >
                <Stack
                    maxWidth={350}
                    gap={2}
                    bgcolor={grey[100]}
                    p={1}
                    borderRadius={10}
                >
                    {
                        loaderZone
                    }

                    <Typography
                        textAlign={"center"}
                    >
                        {
                            textToShow
                        }
                    </Typography>

                    <Stack
                        direction={"column"}
                        gap={2}
                        bgcolor={grey[100]}
                        p={1}
                        borderRadius={10}
                        display={showInputZone ? `flex` : `none`}
                    >
                        <Input
                            placeholder='Saisissez le code à 6 chiffres'
                            type='number'
                            required={true}
                            value={value}
                            onChange={({ target }) => setvalue(target.value)}
                            slotProps={{
                                input: {
                                    ref: inputRef,
                                    min: 0,
                                    max: 999999,
                                },
                            }}
                        />
                        <ButtonGroup
                            fullWidth
                        >
                            <Button
                                color='warning'
                                startIcon={
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                }
                                type='reset'
                                onClick={() => {
                                    window.location.href = `${PROFIL_PAGE_PATH}?tmp=${currentUser?.user_id}`
                                }}
                            >
                                Annuler
                            </Button>
                            <Button
                                endIcon={
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                }
                                type='submit'
                            >
                                Vérifier
                            </Button>
                        </ButtonGroup>
                    </Stack>
                </Stack>
            </form>
        </Stack>
    )
}

export default MailChecker